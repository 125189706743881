<template>
  <div class="common-wrapper">
    <LeftTree ref="leftTree" :treeData="treeData" :timeType="timeType" />

    <div class="content">
      <div>
        <a-tabs v-model:activeKey="timeType" @change="changeTimeTab">
          <a-tab-pane key="1" tab="日抄表"></a-tab-pane>
          <a-tab-pane key="2" tab="月抄表" force-render></a-tab-pane>
        </a-tabs>
        <basic-card :showTitle="false" titleHeight="56px">
          <template #left>
            <a-form layout="inline">
              <a-form-item>
                <a-range-picker
                  v-model:value="time"
                  :picker="timeType == 1 ? 'date' : 'month'"
                />
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="initTableData">查询</a-button>
              </a-form-item>
            </a-form>
          </template>
          <template #right>
            <div>
              <a-button style="margin-right: 16px" @click="handleOut"
                >导出</a-button
              >
              <a-dropdown class="bt_more">
                <a-button
                  v-show="leftTree && leftTree.meterType == 2"
                  style="margin-right: 16px"
                  >批量添加</a-button
                >
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <DownloadButton
                        label="下载导入模板"
                        :customFunc="customDownload"
                      ></DownloadButton>
                    </a-menu-item>
                    <a-menu-item>
                      <UploadButton
                        label="上传导入文件"
                        fileType="file"
                        :customFunc="customUpload"
                        @uploadSuccess="uploadSuccess"
                      >
                      </UploadButton>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <a-button
                v-show="leftTree && leftTree.meterType == 2"
                type="primary"
                @click="handleAdd"
                >添加</a-button
              >
            </div>
          </template>

          <basic-table
            ref="tableRef"
            v-bind="tableConfig"
            @page-change="pageChange"
          >
          </basic-table>
        </basic-card>
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      :title="`${isEditing ? '修改' : '添加'}抄表`"
      @ok="ok"
      @cancel="cancel"
      :confirm-loading="loading"
    >
      <BasicForm
        ref="formRef"
        :labelCol="{ span: 5 }"
        :formItemsMap="formItemsMap"
        v-model:formData="formData"
      >
      </BasicForm>
    </a-modal>
  </div>
</template>
<!-- eslint-disable no-unused-vars -->

<script setup>
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { reactive } from "vue";
import { BasicTable } from "@/components/basic/table";
import LeftTree from "./LeftTree.vue";
import { apiWaterMeter } from "@/api/energyAnalysis/waterMeter";
import dayjs from "dayjs";
import { message } from "ant-design-vue";
import _ from "lodash";
import { calc, fmt } from "a-calc/es";
import { downLoadFile } from "@/utils/excel";

const leftTree = ref();
const treeData = ref([]);

const timeType = ref("1");
const defaultDayTime = [dayjs().add(-4, "d"), dayjs().add(-1, "d")];
const defaultMonthTime = [dayjs().add(-4, "M"), dayjs().add(-1, "M")];
const time = ref(defaultDayTime);

const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  scroll: { y: "calc(100vh - 420px)" },
  columns: [
    {
      title: "水表编号",
      type: "text",
      key: "bizDeviceId",
    },
    {
      title: "名称",
      type: "text",
      key: "name",
    },
    {
      title: "期数",
      type: "text",
      key: "time",
    },
    {
      title: "期初表显",
      type: "text",
      key: "waterStart",
    },
    {
      title: "期末表显",
      type: "text",
      key: "waterEnd",
    },
    {
      title: "本期总电量",
      type: "text",
      key: "waterTotal",
    },
    {
      title: "抄表时间",
      type: "text",
      key: "updateTime",
      formatter(val) {
        return dayjs(val).format("YYYY-MM-DD  HH:mm:ss");
      },
    },
    {
      title: "抄表人员",
      type: "text",
      key: "username",
    },
    {
      title: "备注",
      type: "text",
      key: "remark",
    },
  ],
  action: {
    title: "操作",
    width: 100,
    fixed: "right",
    items: [
      {
        icon: "FormOutlined",
        iconTip: "编辑",
        type: "icon",
        onClick: (row) => {
          handleEdit(row);
        },
      },
    ],
  },
});
const tableRef = ref();

const isEditing = ref(false);
const visible = ref(false);
const loading = ref(false);
const defaultFormData = {
  bizDeviceId: "",
  time: undefined,
  waterStart: "",
  waterEnd: "",
  waterTotal: undefined,
  remark: "",
};
const formData = reactive(_.cloneDeep(defaultFormData));
const formRef = ref();
const formItemsMap = reactive({
  bizDeviceId: {
    label: "选择水表",
    prop: "bizDeviceId",
    type: "select",
    options: [],
    required: true,
  },
  time: {
    label: "选择日期",
    prop: "time",
    type: "datePicker",
    format: "YYYY-MM-DD",
    picker: "date",
    required: true,
  },
  openDisplaysValue: {
    label: "期初表显",
    prop: "waterStart",
    type: "input",
    disabled: true,
    required: false,
  },
  closeDisplaysValue: {
    label: "期末表显",
    prop: "waterEnd",
    type: "inputNumber",
    required: true,
  },
  activeTotal: {
    label: "本期水量",
    prop: "waterTotal",
    type: "inputNumber",
    required: true,
  },
  remark: {
    label: "备注",
    prop: "remark",
    type: "textarea",
  },
});

const menuType = computed(() => {
  if (timeType.value == 1 && leftTree.value?.meterType == 1) {
    // 日远程
    return {
      meterRead: "01",
      meterReadCycle: 1,
    };
  } else if (timeType.value == 2 && leftTree.value?.meterType == 1) {
    // 月远程
    return {
      meterRead: "01",
      meterReadCycle: 2,
    };
  } else if (timeType.value == 1 && leftTree.value?.meterType == 2) {
    // 日手动
    return {
      meterRead: "02",
      meterReadCycle: 1,
    };
  } else if (timeType.value == 2 && leftTree.value?.meterType == 2) {
    // 月手动
    return {
      meterRead: "02",
      meterReadCycle: 2,
    };
  }
  return "";
});
const timeFormat = computed(() => {
  return timeType.value == 1 ? "YYYY-MM-DD" : "YYYY-MM";
});

watch(
  () => menuType.value,
  () => {
    initMenu();
  },
  {
    deep: true,
  }
);

watch(
  () => [formData.bizDeviceId, formData.time],
  ([bizDeviceId, time]) => {
    if (bizDeviceId && time) {
      let func = timeType.value == 1 ? "dayMeterDisplay" : "monthMeterDisplay";
      apiWaterMeter
        .waterStart({
          bizDeviceId,
          staTime: time,
          meterReadCycle: timeType.value,
        })
        .then(({ result }) => {
          formData.waterStart = result;
        });
    }
  }
);

watch(
  () => formData.waterEnd,
  (closeDisplaysValue) => {
    let start = formData.waterStart;
    if (start || start === 0 || start === "0") {
      let end = closeDisplaysValue;
      formData.waterTotal = calc(" end - start ", { end, start });
    }
  }
);

const initMenu = () => {
  if (menuType.value) {
    apiWaterMeter.treeMenu(menuType.value).then(({ result }) => {
      treeData.value = result;
      tableRef.value.paginationProps.current = 1;
      nextTick(() => {
        initTableData();
      });
    });
  }
};

const changeTimeTab = (tab) => {
  time.value = tab == 1 ? defaultDayTime : defaultMonthTime;
  formItemsMap.time.format = tab == 1 ? "YYYY-MM-DD" : "YYYY-MM";
  formItemsMap.time.picker = tab == 1 ? "date" : "month";
};

const initTableData = () => {
  let devices = leftTree.value.checkedKeys;
  if (devices.length) {
    let m = {
      pageNo: tableRef.value.paginationProps.current,
      pageSize: tableRef.value.paginationProps.pageSize,
      start: dayjs(time.value[0]).format(timeFormat.value),
      end: dayjs(time.value[1]).format(timeFormat.value),
      bizDeviceIds: devices,
      meterReadCycle: timeType.value * 1,
    };
    apiWaterMeter.pageList(m).then(({ result }) => {
      tableRef.value.dataSourceRef = result.records || [];
      tableRef.value.paginationProps.total = result.total;
    });
    initDeviceList();
  } else {
    message.warning("没有满足条件的水表!");
    tableRef.value.dataSourceRef = [];
    tableRef.value.paginationProps.total = 0;
  }
};

const handleEdit = (row) => {
  initDeviceList();
  isEditing.value = true;
  visible.value = true;
  formData.bizDeviceId = row.bizDeviceId + "";
  formData.time = dayjs(row.time).format(timeFormat.value);
  formData.waterStart = row.waterStart;
  formData.waterEnd = row.waterEnd;
  formData.waterTotal = row.waterTotal;
  formData.remark = row.remark;
  formData.id = row.id;
};

const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const initDeviceList = () => {
  // apiWaterMeter.deviceList({ meterReadCycle: timeType.value }).then((res) => {
  //   formItemsMap.bizDeviceId.options = res.result || [];
  // });
  formItemsMap.bizDeviceId.options = leftTree.value.getDevices();
};
const handleAdd = () => {
  isEditing.value = false;
  visible.value = true;
  initDeviceList();
};

const ok = () => {
  formRef.value.formRef.validateFields().then(() => {
    loading.value = true;
    let m = { meterReadCycle: timeType.value, ...formData };
    apiWaterMeter
      .update(m)
      .then(() => {
        message.success(`${formData.id ? "编辑" : "新增"}成功`);
        visible.value = false;
        initTableData();
      })
      .finally(() => {
        loading.value = false;
      });
  });
};
const cancel = () => {
  for (const key in defaultFormData) {
    formData[key] = defaultFormData[key];
  }
  delete formData.id;
};

const handleOut = () => {
  let devices = leftTree.value.checkedKeys;
  let data = {
    bizDeviceIds: devices,
    meterReadCycle: timeType.value,
    start: dayjs(time.value[0]).format(timeFormat.value),
    end: dayjs(time.value[1]).format(timeFormat.value),
  };
  apiWaterMeter.export(data).then((res) => {
    const { filename, blob } = res;
    downLoadFile(blob, decodeURI(filename));
    message.success("导出成功");
  });
};

const customDownload = async () => {
  let res =  await apiWaterMeter.downTemplate();
  res.filename = '水表抄表数据导入模板'
  return res;
};
const customUpload = async (data) => {
  return await apiWaterMeter.upload(data);
};
const uploadSuccess = () => {
  tableRef.value.paginationProps.current = 1;
  initTableData();
};
</script>
<style lang="less" scoped>
.common-wrapper {
  display: flex;
  padding: 0;
}

.content {
  display: flex;
  flex: 1;
  gap: 16px;
  padding: 0 16px;
  height: calc(100vh - 189px);
}
</style>
