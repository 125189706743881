import http from "../../utils/http";

// 水表抄表
export const apiWaterMeter = {
  // 获取菜单列表
  treeMenu(params) {
    return http.request({
      url: "/cemp-energy/water-meter-reading/tree",
      method: "get",
      params,
    });
  },
  // 分页查询
  pageList(data) {
    return http.request({
      url: "/cemp-energy/water-meter-reading/page",
      method: "post",
      data,
    });
  },
  // 编辑-详情
  detail(params) {
    return http.request({
      url: "/cemp-energy/water-meter-reading/detail",
      method: "get",
      params,
    });
  },
  // 查询期初表显（日）
  waterStart(params) {
    return http.request({
      url: "/cemp-energy/water-meter-reading/get-water-start",
      method: "get",
      params,
    });
  },
  // 查询期初表显（日）
  deviceList(params) {
    return http.request({
      url: "/cemp-energy/water-meter-reading/meter-devices",
      method: "get",
      params,
    });
  },
  // 编辑/新增
  update(data) {
    return http.request({
      url: "/cemp-energy/water-meter-reading/save-or-update",
      method: "put",
      data,
    });
  },

  // 日远程表列表
  dayRemoteMeterList(params) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/page/day/sta",
      method: "get",
      params,
    });
  },
  // 日手抄表列表
  dayMeterList(params) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/page/day/manual",
      method: "get",
      params,
    });
  },
  // 月远程表列表
  monthRemoteMeterList(params) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/page/month/sta",
      method: "get",
      params,
    });
  },
  // 月手抄表列表
  monthMeterList(params) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/page/month/manual",
      method: "get",
      params,
    });
  },
  // 查询电表设备的倍率
  meterFactor(params) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/multiplying-factor",
      method: "get",
      params,
    });
  },
  // 查询期初表显（日）
  dayMeterDisplay(params) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/open-displays-value",
      method: "get",
      params,
    });
  },
  // 查询期初表显（月）
  monthMeterDisplay(params) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/close-displays-value",
      method: "get",
      params,
    });
  },

  // 新增
  addDay(data) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/day/manual",
      method: "post",
      data,
    });
  },
  // 新增
  addMonth(data) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading/month/manual",
      method: "post",
      data,
    });
  },
  // 编辑
  edit(data) {
    return http.request({
      url: "/cemp-energy/electricity-meter-reading",
      method: "put",
      data,
    });
  },
  // 删除
  deleteDay(id) {
    return http.request({
      url: `/cemp-energy/electricity-meter-reading/day/manual/${id}`,
      method: "delete",
    });
  },
  // 删除
  deleteMonth(id) {
    return http.request({
      url: `/cemp-energy/electricity-meter-reading/month/manual/${id}`,
      method: "delete",
    });
  },
  // 下载模板
  downTemplate() {
    return http.request({
      url: `/cemp-file/file/download/meter-import-excel`,
      method: "get",
      responseType: "blob",
    });
  },
  //上传模板
  upload(data) {
    return http.request({
      url: `/cemp-energy/water-meter-reading/import`,
      method: "post",
      data,
    });
  },
   //导出
   export(data) {
    return http.request({
      url: `/cemp-energy/water-meter-reading/export`,
      method: "post",
      responseType: "blob",
      data,
    });
  },
};
