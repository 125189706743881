<template>
    <div class='left-tree'>
        <a-tabs centered v-model:activeKey="meterType" style="width: 100%;">
            <a-tab-pane key="1" tab="远程表"></a-tab-pane>
            <a-tab-pane key="2" tab="手抄表" force-render></a-tab-pane>
        </a-tabs>
        <a-tree :checkedKeys="checkedKeys" @check="check" checkable :tree-data="treeData"
            :field-names="{ title: 'name', children: 'children', key: 'id' }">
        </a-tree>
    </div>
</template>

<script setup>
import { ref, defineExpose, watch, defineProps } from 'vue';
import { message } from "ant-design-vue";
const props = defineProps(['treeData'])
const meterType = ref('1')

const selectedItem = ref();
const checkedKeys = ref([]);

const findFirstChildrenKey = () => {
    return props.treeData[0]?.children.map(e => e.id)
}

watch(() => props.treeData, () => {
    if (props.treeData.length) {
        checkedKeys.value = findFirstChildrenKey()
        selectedItem.value = props.treeData[0]
    } else {
        checkedKeys.value = []
        selectedItem.value = undefined
    }
});

const getDevices = () => {
    return selectedItem.value ? selectedItem.value.children.map(e => {
        return {
            label: e.name,
            value: e.id
        }
    }) : []
}

defineExpose({
    meterType,
    getDevices,
    checkedKeys
})


const check = (keys, e) => {
    if (e.node.parent) {
        // 操作的水表
        if (selectedItem.value && e.node.parent.key !== selectedItem.value.id) {
            message.error("不能同时选择不同项目的设备，请先取消其他设备！");
        } else {
            checkedKeys.value = keys
            selectedItem.value = keys.length ? e.node.parent.node : undefined
        }
    } else {
        if(selectedItem.value && (e.node.id != selectedItem.value.id)){
            message.error("不能同时选择不同项目的设备，请先取消其他设备！");
        }else{
            //操作了项目
            selectedItem.value = keys.length ? e.node.dataRef : undefined
            checkedKeys.value = keys
        }
       
    }
}



</script>
<style lang='less' scoped>
.left-tree {
    width: 248px;
    height: calc(100vh - 105px);
    border-right: 8px solid #f0f2f5;
}

:deep .ant-tree {
    height: calc(100vh - 255px);
    overflow: auto;
}

:deep .ant-tabs-tab {
    margin: 0 23px;
}
</style>